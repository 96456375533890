<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div v-if="!getMenu">
        <div class="mx-auto sm:max-w-xl md:max-w-full xl:max-w-screen-2xl p-2">
          <div class="text-gray-800 mt-10">
            <a
              v-on:click="$router.go(-1)"
              class="flex items-center space-x-2 text-center cursor-pointer"
              ><i class="fad fa-arrow-left mr-1"></i><span>Go Back</span></a
            >
          </div>

          <div class="mt-5">
            <div
              class="grid gap-4 grid-cols-1 lg:grid-cols-2 md:grid-cols-2 mt-10"
            >
              <div class="text-center">
                <img class="w-full" :src="phoneData.img" />
              </div>

              <div class="flex items-center">
                <div>
                  <h3 class="text-4xl font-sans font-medium mt-10">
                    MR.DONOTHING-WORK FROM HOME
                  </h3>

                  <h3 class="text-2xl font-sans font-medium mt-5">
                    iPhone 14 Pro Bounce Extreme Case MagSafe Compatible
                    Waterproof
                  </h3>

                  <h3 class="text-5xl mt-6 text-gray-700">
                    THB2599 － Free Shipping
                  </h3>

                  <router-link to="/creditcardtopup" class="flex justify-start">
                    <div
                      class="inline-block mt-5 p-px bg-gradient-to-r from-blue-700 to-red-500 rounded-2xl"
                    >
                      <div
                        class="inline-block text-lg px-2 py-1 sm:px-4 sm:py-4 border-none rounded-2xl text-gray-200 bg-gray-900 cursor-pointer hover:bg-gray-200 hover:text-gray-900"
                      >
                        CONTINUE TO PAYMENT
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
            <hr class="mt-16 mb-16" />
          </div>
        </div>

        <div class="mx-auto sm:max-w-xl md:max-w-full xl:max-w-screen-lg p-2">
          <div>
            <div class="grid gap-2 grid-cols-2 lg:grid-cols-3 md:grid-cols-3">
              <div>
                <div class="flex justify-center">
                  <img
                    src="https://cdn-stamplib.casetify.com/cms/image/19e4d87c4f7e287004d09b6bf0621ec9.png"
                    class="w-1/2"
                  />
                </div>

                <div class="text-center mt-2">
                  <h3 class="text-xl">
                    Bounce corner for

                    <br />
                    extreme protection
                  </h3>
                </div>
              </div>

              <div>
                <div class="flex justify-center">
                  <img
                    src="https://cdn-stamplib.casetify.com/cms/image/b5a6189078e15873b15550ff42225463.png"
                    class="w-1/2"
                  />
                </div>

                <div class="text-center mt-2">
                  <h3 class="text-xl">21.3ft drop protection</h3>
                </div>
              </div>

              <div>
                <div class="flex justify-center">
                  <img
                    src="https://cdn-stamplib.casetify.com/cms/image/b5a6189078e15873b15550ff42225463.png"
                    class="w-1/2"
                  />
                </div>

                <div class="text-center mt-2">
                  <h3 class="text-xl">
                    EcoShock™ Protection <br />
                    Technology
                  </h3>
                </div>
              </div>

              <div>
                <div class="flex justify-center">
                  <img
                    src="https://cdn-stamplib.casetify.com/cms/image/ecc085ca6b89a6d935c69e326cbfe15b.png"
                    class="w-1/2"
                  />
                </div>

                <div class="text-center mt-2">
                  <h3 class="text-xl">6x MIL-STD-810G</h3>
                </div>
              </div>
              <div>
                <div class="flex justify-center">
                  <img
                    src="https://cdn-stamplib.casetify.com/cms/image/19c3876a5796d4f96097aca0b46dfd8c.png"
                    class="w-1/2"
                  />
                </div>

                <div class="text-center mt-2">
                  <h3 class="text-xl">
                    Raised bezel for screen <br />
                    protection
                  </h3>
                </div>
              </div>

              <div>
                <div class="flex justify-center">
                  <img
                    src="https://cdn-stamplib.casetify.com/cms/image/c2c8fd9b9151dc8f1db6ef465a898354.png"
                    class="w-1/2"
                  />
                </div>

                <div class="text-center mt-2">
                  <h3 class="text-xl">
                    Wireless charging and <br />
                    MagSafe compatible
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <hr class="mt-16 mb-16" />
        </div>

        <div class="mx-auto sm:max-w-xl md:max-w-full xl:max-w-screen-xl p-2">
          <div class="">
            <video playsinline autoplay muted loop>
              <source
                src="https://cdn-stamplib.casetify.com/cms/video/bb65e8b323cb11ace2430ee8a8a57447.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>

          <div class="mt-10 text-center">
            <h3 class="text-5xl font-sans font-extrabold">
              The Bounce Case: Our Most Protective Case. Ever.
            </h3>
            <h3 class="text-xl mt-10">
              Introducing the fun-loving, all-adventure ready Bounce Case; the
              most protective phone case we've ever created for the iPhone 14,
              and gold winner of the 2023 NY Product Design Award.
            </h3>
            <br />
            <h3 class="text-xl">
              With a cutting-edge corner design and EcoShock™ impact-absorption
              technology, the Bounce Case absorbs 95% of any shock, ensuring
              your phone stays light and protected.
            </h3>

            <img
              src="https://cdn-stamplib.casetify.com/cms/image/3b5491e3aacf5ba00c45d4352ac8c6ab.jpg"
              class="mt-10"
            />
          </div>
          <div class="mt-10">
            <div class="">
              <video playsinline autoplay muted loop>
                <source
                  src="https://cdn-stamplib.casetify.com/cms/video/15d007c68f084859193f4527a714b393.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>

          <div class="mt-10 text-center">
            <h3 class="text-5xl font-sans font-extrabold">
              Tested to the Extreme
            </h3>
            <h3 class="text-xl mt-10">
              Some people do one drop test and call it a day. Not us. Because we
              know your phone goes through more bangs and bumps than that. We
              drop each case up to 156 times consecutively on all angles and
              from a drop height of up to 21.3ft.
            </h3>
            <br />
            <h3 class="text-xl">
              We check for weaknesses or design faults. And if we find them? We
              start over. Crafting and polishing, every tiny unnoticeable detail
              until it’s perfect.
            </h3>

            <div class="mt-10">
              <div class="">
                <video playsinline autoplay muted loop>
                  <source
                    src="https://cdn-stamplib.casetify.com/cms/video/0681fd424c2ab31545ca78d87333ecc2.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>

        <StickFooter class="mt-44" />
      </div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/Footer.vue";

import { mapGetters, mapActions } from "vuex";

import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";

// import { products, main_product, products_2 } from "../../products";
import caseExample from "../../caseExample";

export default {
  data() {
    return {
      _id: this.$route.params._id,
      caseExample: caseExample,
      phoneData: {},
    };
  },
  components: {
    ConnectWallet,
    StickFooter,
    Carousel,
    Navigation,
    Pagination,
    Slide,
  },
  computed: mapGetters(["getMenu"]),

  methods: {},
  created() {
    var data = caseExample.caseExample.filter((product) => {
      return product._id == this._id;
    });

    this.phoneData = data[0];
  },
};
</script>
